import type { TRedirectItem } from '~/lib/redirect/redirect.type.js';
import { models as __models } from '../lib/dato/dato.js';
import __projectConfig from '~/project.config.js';

export default async function redirectGlobalDato(to: any) {
  // get the redirects from the CMS
  // @TODO          cache this
//   const redirects = await __models.redirect.getRedirects();

//   // get the locale from the project config
//   let locale = __projectConfig.locale.default;

//   // check from path if a locale is present
//   // if so, set the locale to this value
//   const potentialLocale = to.path.match(/^\/([a-z]{2})\//)?.[1];
//   if (potentialLocale) {
//     const availableLocales = __projectConfig.locale.locales.map((l) => l.code);
//     if (availableLocales.includes(potentialLocale)) {
//       locale = potentialLocale;
//     }
//   }

//   // check if the locale is the default locale
//   const isDefaultLocale = locale === __projectConfig.locale.default;

//   // prepare the destination path
//   let destination: string = to.path;

//   // check if the path has a redirect
//   for (let redirect of redirects) {
//     const potentialSource = `/${isDefaultLocale ? '' : `${locale}/`}${redirect.source[locale]}`,
//       potentialDestination = `/${isDefaultLocale ? '' : `${locale}/`}${redirect.destination[locale]}`;

//     if (destination === potentialSource) {
//       destination = potentialDestination;
//     }
//   }

//   // if the destination is different from the current path, redirect
//   if (destination !== to.path) {
//     return navigateTo(destination, { redirectCode: 301 });
//   }
}
